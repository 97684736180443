<template>
	<div>
		<div class="d-flex justify-content-end mb-2">
			<div class="d-flex gap-x-2" v-if="tabIndex == 0">
				<b-button variant="primary" class="position-relative" @click="showPadrillos = true">
					Padrillos en postura
					<b-badge variant="light" v-if="counters.in_posture > 0">
						<span class="text-primary">{{ counters.in_posture }}</span>
					</b-badge>
				</b-button>
				<b-button
					variant="primary"
					class="position-relative"
					@click="posturasActiveModal = !posturasActiveModal"
				>
					Padrillos dispuestos
					<b-badge variant="light" v-if="counters.availables > 0">
						<span class="text-primary">{{ counters.availables }}</span>
					</b-badge>
				</b-button>
				<b-button variant="success" @click="openModalAddMadrillaRecomended">
					Agregar
					<FeatherIcon icon="PlusCircleIcon" class="ml-50" />
				</b-button>
			</div>
		</div>

		<div class="card mb-0">
			<div v-if="tabIndex == 1" class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex flex-column align-items-start justify-content-between"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
							<div class="xt-search-input__paginate">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									class=""
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>

		<b-table-simple responsive class="cst-table">
			<b-thead class="cst-head">
				<b-tr class="text-center">
					<b-th>#</b-th>
					<b-th class="text-center px-2 align-middle">Madrilla</b-th>
					<b-th class="text-center px-2 align-middle" v-if="tabIndex == 1">Padrillo</b-th>
					<b-th class="text-center px-2 align-middle" v-if="tabIndex == 0"># Huevos</b-th>
					<b-th class="text-center px-2 align-middle" v-if="tabIndex == 0">Ultimo registro por</b-th>
					<b-th class="text-center px-2 align-middle" v-if="tabIndex == 0">Acciones</b-th>
					<b-th class="text-center px-2 align-middle" v-if="tabIndex == 1">Postura</b-th>
				</b-tr>
			</b-thead>

			<b-tbody>
				<b-tr
					v-if="madrillas.length > 0"
					class="text-center"
					v-for="(madrilla, index) in madrillas"
					:key="madrilla.id"
					@mouseover="clearQueryMadrilla(madrilla)"
				>
					<b-th>{{ index + 1 }}</b-th>
					<b-th>
						<SpecimenPlate
							class="font-weight-normal"
							:specimen="{
								id: madrilla.madrilla.id,
								plate: madrilla.madrilla.plate,
								alias: madrilla.madrilla.alias,
								thumb: madrilla.madrilla.thumb,
								image: madrilla.madrilla.image,
							}"
						/>
					</b-th>
					<b-th v-if="tabIndex == 0" class="font-weight-normal">{{ madrilla.eggs }}</b-th>
					<b-th v-if="tabIndex == 0">
						<p class="mb-0 font-weight-normal">{{ madrilla.last_register.user }}</p>
						<span class="font-small-2 text-secondary font-weight-normal">
							{{ madrilla.last_register.date | myGlobalDayShort }}
						</span>
					</b-th>

					<b-th v-if="tabIndex == 0">
						<!-- <FeatherIcon
							@click="deleteMadrillaRecommended(madrilla.id)"
							icon="TrashIcon"
							class="text-danger mr-50 cursor-pointer"
							size="20"
						/> -->
						<div class="d-flex flex-column align-items-center">
							<b-button
								@click="deleteMadrillaRecommended(madrilla.id)"
								style="width: 150px"
								class="btn-sm"
								lick="deleteMadrillaRecommended(madrilla.id)"
								variant="danger"
							>
								Eliminar
							</b-button>
							<b-button
								style="width: 150px"
								class="btn-sm mt-75"
								:variant="disabledEncaste ? 'secondary' : 'primary'"
								:disabled="disabledEncaste"
								@click="!disabledEncaste && handleCreateNewPosture(madrilla.madrilla)"
							>
								Agregar postura
							</b-button>
						</div>
						<!-- <feather-icon
							icon="PlusCircleIcon"
							size="24"
							:class="disabledEncaste ? 'text-secondary' : 'cursor-pointer text-primary'"
							@click="!disabledEncaste && handleCreateNewPosture(madrilla.madrilla)"
						/> -->
					</b-th>

					<b-th v-if="tabIndex == 1">
						<SpecimenPlate
							class="font-weight-normal"
							:specimen="{
								id: madrilla.padrillo.id,
								plate: madrilla.padrillo.plate,
								alias: madrilla.padrillo.alias,
								thumb: madrilla.padrillo.thumb,
								image: madrilla.padrillo.image,
							}"
						/>
					</b-th>
					<b-th v-if="tabIndex == 1">
						<p class="mb-0">{{ madrilla.postura_code }}</p>
						<span class="font-weight-normal">{{ madrilla.postura_start_date | myGlobalDayShort }}</span>
					</b-th>
				</b-tr>
				<b-tr v-if="madrillas.length == 0">
					<b-th colspan="5">
						<p class="text-center mb-0">Sin madrillas Recomendadas</p>
					</b-th>
				</b-tr>
			</b-tbody>
		</b-table-simple>

		<!-- <AddPosture
			v-if="openModal"
			:madrillaController="true"
			:placa="currentSpecimen.plate"
			:madrilla_id="currentSpecimen.id"
			:alias="currentSpecimen.alias"
			@hidden="openModal = false"
			@refresh="
				$emit('refresh')
				init()
			"
			is_eggs_air
		/> -->

		<AddPosturaEggs
			v-if="openModal"
			:madrillaController="true"
			:placa="currentSpecimen.plate"
			:madrilla_id="currentSpecimen.id"
			:alias="currentSpecimen.alias"
			@hidden="openModal = false"
			@refresh="
				$emit('refresh')
				init()
			"
			is_eggs_air
		/>

		<AddPosturaActiveTask :show="posturasActiveModal" @closing="handleNewPostura" />

		<PadrillosInPostureModal v-if="showPadrillos" @closing="showPadrillos = false" @refresh="init" />

		<b-modal
			centered
			no-close-on-backdrop
			@ok.prevent="addEggTheAir"
			:ok-title="`Agregar`"
			v-model="viewAddMadrillaRecomended.show"
			:title="viewAddMadrillaRecomended.title"
		>
			<b-row>
				<b-col cols="12" md="6">
					<b-form-group id="madrilla" label="Elige la madrilla:" label-for="madrilla">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								:style="{ marginTop: '8px' }"
								v-model="viewAddMadrillaRecomended.madrilla_id"
								:options="arrayMadrillas"
								:clearable="false"
								id="madrilla"
								label="alias"
								:filter="fuseSearch"
								:reduce="(option) => option.id"
								:class="{ 'border-danger rounded': errors[0] }"
							>
								<template #selected-option="{ alias, plate }">
									<span v-if="alias" v-text="`${alias} / (${plate})`"></span>
									<span v-else v-text="`(${plate})`"></span>
								</template>
								<template v-slot:option="{ alias, plate }">
									<span v-if="alias" v-text="`${alias} / (${plate})`"></span>
									<span v-else v-text="`(${plate})`"></span>
								</template>
							</v-select>
							<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
						</ValidationProvider>
					</b-form-group>
				</b-col>
				<b-col cols="12" md="6">
					<b-form-group label="Cantidad:" label-for="eggs">
						<vue-number-input
							v-model="viewAddMadrillaRecomended.eggs"
							:min="1"
							:max="10"
							inline
							controls
							center
							style="max-width: 150px; margin-top: 5px"
							class="text-center"
						/>
					</b-form-group>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import AddPosture from "@/views/amg/encaste/posturas/modals/AddPostura.vue"
import AddPosturaEggs from "@/views/amg/encaste/posturas/modals/AddPosturaEggs.vue"
import recommendedService from "@/services/recommended.service"
import { bus } from "@/main"
import EggService from "@/services/egg.service"
import AddPosturaActiveTask from "@/views/amg/encaste/posturas/modals/AddPosturaActiveTask.vue"
import PadrillosInPostureModal from "@/views/amg/encaste/posturas/modals/PadrillosInPostureModal.vue"
import EncasteService from "@/services/encaste.service"
import counters from "@/store/modules/tournament/counters"

export default {
	name: "RecommendedMadrillas",
	components: {
		SpecimenPlate,
		AddPosture,
		AddPosturaEggs,
		AddPosturaActiveTask,
		PadrillosInPostureModal,
	},
	props: {
		eggs: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			madrillas: [],
			currentSpecimen: null,
			openModal: false,
			perpage: 100,
			page: 1,
			viewAddMadrillaRecomended: {
				show: false,
				title: "Agregar madrilla recomendada",
				madrilla_id: null,
				eggs: 1,
			},
			arrayMadrillas: [],
			madrilla_id: null,
			tabIndex: 0,
			start_page: 1,
			to_page: 1,
			total_data: 0,
			current_page: 1,
			posturasActiveModal: false,
			showPadrillos: false,
			counters: {},
		}
	},
	computed: {
		styleForSpecimenBusy: () => (specimen, user) => ({
			"pointer-events-none": specimen.status !== "ACTIVO" || !user,
			"text-muted": specimen.status !== "ACTIVO" || !user,
		}),
		isCreatable() {
			return this.currentUser.id == 1
		},
	},
	async mounted() {
		this.counterMadrillas = this.eggs
		await this.init()
		// await this.initCounterMadrillasRecomended();
	},
	async created() {
		bus.$on("reload-madrillas-recomendadas", async () => {
			await this.init()
		})
		await this.getCounters()
	},
	destroyed() {
		bus.$off("reload-madrillas-recomendadas")
	},
	methods: {
		clearQueryMadrilla(madrilla) {
			if (this.$route.query.madrilla_id == madrilla.madrilla.id) {
				setTimeout(() => {
					this.$router.push({ query: null })
				}, 300)
			}
		},
		handleNewPostura() {
			this.posturasActiveModal = !this.posturasActiveModal
			// this.initCounters()
			// this.refreshRouter()
		},
		async deleteMadrillaRecommended(id) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()

			const { status, message } = await recommendedService.delete({
				recomended_id: id,
			})
			this.isPreloading(false)

			// console.log({ status, message });

			if (!status) {
				this.showToast("info", "top-right", "Validación", "AlertCircleIcon", message)
				return
			}
			this.showSuccessToast(message)
			await this.init()
			await this.$emit("refresh")
		},
		async getCounters() {
			this.isPreloading()
			try {
				const { data } = await EncasteService.getCounters()
				this.counters = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		handleCreateNewPosture(specimen) {
			this.currentSpecimen = specimen
			// console.log("madrilla: ", specimen)
			this.isPreloading(true)
			this.openModal = true
		},
		async init() {
			this.madrillas = []
			this.isPreloading()

			const { madrillas, message } = await recommendedService.get({
				page: this.page,
				perpage: this.perpage,
			})
			this.madrillas = madrillas

			this.isPreloading(false)
		},
		async openModalAddMadrillaRecomended() {
			this.viewAddMadrillaRecomended.show = true
			await this.getActiveMadrillasByActivePosturas()
		},
		async getActiveMadrillasByActivePosturas() {
			try {
				const { data } = await axios.get("api/postura/get-madrillas-being-used")
				this.arrayMadrillas = data
			} catch (error) {
				console.error(error)
				throw error
			}
		},
		async addEggTheAir() {
			// const { isConfirmed } = await this.showConfirmSwal();
			// if (!isConfirmed) return;
			this.isPreloading()
			const { message } = await EggService.insertNotification({
				madrilla_id: this.viewAddMadrillaRecomended.madrilla_id,
				quantity: this.viewAddMadrillaRecomended.eggs,
			})
			this.isPreloading(false)
			this.showToast("success", "top-right", "Huevos al aire", "SuccessIcon", message)
			await this.init()
			// await this.initCounterMadrillasRecomended();
			this.viewAddMadrillaRecomended.show = false
			this.viewAddMadrillaRecomended.madrilla_id = null
			this.viewAddMadrillaRecomended.eggs = 1
			this.$emit("refresh")
		},
		// async initCounterMadrillasRecomended() {
		// 	const { count } = await EggService.counterMadrillasRecomended();
		// 	this.eggs = count;
		// },
		fuseSearch(options, search) {
			const fuse = new Fuse(options, {
				keys: ["plate", "alias"],
				shouldSort: true,
			})
			return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
		},
	},
	watch: {
		tabIndex() {
			this.init()
		},
		current_page() {
			this.init()
		},
	},
}
</script>

<style lang="scss">
.row-table-posture {
	transition: 1s all ease-in-out;
}
.bg-select-id-madrilla {
	background: rgba($color: #000000, $alpha: 0.1);
	cursor: pointer;
}
</style>
