<template>
	<b-modal
		ref="modal-add-postura-eggs"
		@hidden="$emit('hidden')"
		:title="title"
		no-close-on-backdrop
		cancel-title="Cancelar"
		scrollable
		:centered="is_eggs_found"
		size="md"
	>
		<div>
			<ValidationObserver ref="form">
				<b-col
					v-for="(postura, index) in arrayPosturas"
					:key="`${index}-postura`"
					class="position-relative pr-2 pl-2"
				>
					<b-row>
						<b-form-group :label-for="'madrilla-' + index" label="Padrillo:" class="w-full">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-input
									:value="formattedMadrilla(postura.padrillo)"
									:id="'madrilla-' + index"
									:class="{ 'border-danger rounded': errors[0] }"
									:disabled="true"
								/>
								<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
						<!-- {{ postura.padrillo }} -->
					</b-row>
					<b-row v-if="!same_padrillo && !withPad">
						<!-- {{ postura.padrillo }} -->
						<b-form-group
							:label-for="'padrillo-' + index"
							label="Elige la madrilla:"
							description=""
							class="w-full"
						>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									:id="'padrillo' + index"
									v-model="postura.madrilla"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="arrayMadrillas"
									:clearable="false"
									label="alias"
									:reduce="(option) => option.madrilla"
									placeholder="Seleccione una madrilla"
									:class="{ 'border-danger rounded': errors[0] }"
									:filter="fuseSearch"
								>
									<template #selected-option="{ madrilla }">
										<span
											v-if="madrilla.alias"
											v-text="`${madrilla.alias} / (${madrilla.plate})`"
										></span>
										<span v-else v-text="`(${madrilla.plate})`"></span>
									</template>
									<template v-slot:option="opt">
										<span>
											{{ opt.madrilla.alias || "---" }} / ({{ opt.madrilla.plate || "---" }})
										</span>
									</template>
								</v-select>
								<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
						</b-form-group>
					</b-row>
					<b-row>
						<b-form-group id="date" label="Fecha" label-for="date" class="w-full">
							<b-form-datepicker
								id="date"
								:date-format-options="{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								}"
								v-model="postura.postura_date"
							/>
						</b-form-group>
					</b-row>
					<b-row>
						<b-form-group id="observation" label="Comentario" label-for="observation" class="w-full">
							<b-form-textarea
								id="observation"
								rows="3"
								v-model="postura.observation"
								style="resize: none"
							/>
						</b-form-group>
					</b-row>
				</b-col>
			</ValidationObserver>

			<div v-if="is_eggs_found" class="ml-2 mr-2">
				<p class="alert alert-warning p-1 w-100">Esta postura sera creada en estado pendiente.</p>
			</div>
		</div>
		<template #modal-footer="{ cancel }">
			<b-button @click="cancel()" variant="secondary">Cancelar</b-button>
			<b-button variant="primary" @click="save()" v-if="!loading">Guardar</b-button>
			<b-button variant="primary" disabled v-else>
				<b-spinner small />
				Cargando...
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import moment from "moment"
import axios from "@/axios"
import posturasService from "@/services/posturas.service"
import { bus } from "@/main"
import fertilidadService from "@/views/amg/encaste/posturas/insemination/fertilidad.service.js"

export default {
	name: "AddPosturaEggs",
	props: {
		padrillo: {
			type: Object,
			required: true,
			default: null,
		},
		placa: String,
		madrilla_id: { type: Number, required: false, default: null },
		alias: { type: String, required: false, default: null },
		madrillaController: Boolean,
		fromGrid: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "Agregar postura",
		},
		same_padrillo: false,
		is_insemination: {
			type: Boolean,
			default: false,
		},
		withPad: {
			type: Boolean,
		},
		task_id: {
			type: Number,
		},
		padrillo_id: {
			type: Number,
		},
		is_eggs_air: {
			type: Boolean,
			default: false,
		},
		is_eggs_found: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [modalMixin],
	async mounted() {
		this.isPreloading()
		await Promise.all([this.getActivePadrillos(), this.getActiveMadrillasByActivePosturas()])

		// if (this.placa) {
		// if (this.madrillaController) {
		// for (let i = 0; i < this.arrayMadrillas.length; i++) {
		// if (this.arrayMadrillas[i].plate == this.placa) {
		// this.arrayPosturas[0].madrilla = this.arrayMadrillas[i]
		// }
		// }
		// if (this.arrayPosturas[0].madrilla !== null) {
		// this.selectOnChange(this.arrayPosturas[0], 0)
		// }
		// } else {
		// for (let i = 0; i < this.arrayPadrillos.length; i++) {
		// if (this.arrayPadrillos[i].plate == this.padrillo.plate) {
		this.arrayPosturas[0].padrillo = this.padrillo
		// }
		// }
		// }
		// }
		this.toggleModal("modal-add-postura-eggs")

		// if (this.same_padrillo) {
		//padrillo_id
		// this.arrayPosturas[0].padrillo = this.padrillo_id
		// }

		// if (this.is_eggs_air) {
		// this.arrayPosturas = [this.arrayPosturas[0]]
		// }

		this.isPreloading(false)
	},
	computed: {},
	data() {
		return {
			loading: false,
			arrayMadrillas: [],
			arrayPadrillos: [],
			arrayPosturas: [
				{
					madrilla: null,
					padrillo: null,
					observation: null,
					postura_date: moment().format("YYYY-MM-DD"),
				},
			],
			disabledCounter: 0,
			arrayMadrillasSelected: [],
			// posturaObject:
			myFilter: (option, label, search) => {
				let temp = search.toLowerCase()
				return option.plate.toLowerCase().indexOf(temp) > -1 || option.alias.toLowerCase().indexOf(temp) > -1
			},
		}
	},
	methods: {
		formattedMadrilla(madrilla) {
			if (madrilla.alias) {
				return `${madrilla.alias} / (${madrilla.plate})`
			} else {
				return `(${madrilla.plate})`
			}
		},
		fuseSearch(options, search) {
			const fuse = new Fuse(options, {
				keys: ["plate", "alias"],
				shouldSort: true,
			})
			return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
		},
		addNewPostura() {
			let obj = {
				madrilla: null,
				padrillo: null,
				observation: null,
				postura_date: moment().format("YYYY-MM-DD"),
			}

			if (this.same_padrillo) {
				obj.padrillo = this.arrayPosturas[0].padrillo
			}

			this.arrayPosturas.push(obj)
		},
		deletePostura(post, index) {
			if (this.arrayPosturas.length <= 1) {
				this.$swal({
					title: "Error",
					text: "Debe haber al menos una postura",
					icon: "error",
				})
				return
			}
			this.arrayPosturas = this.arrayPosturas.filter((it, key) => index != key)
		},
		selectOnChange(postura, index) {
			this.arrayMadrillasSelected.push({
				id: index,
				postura,
			})
			if (this.arrayMadrillasSelected.filter((v) => v.id == index).length > 1) {
				var idx = this.arrayMadrillasSelected.findIndex((i) => i.id == index)
				if (idx >= 0) {
					this.arrayMadrillasSelected.splice(idx, 1)
				}
			}
			for (let i = 0; i < this.arrayMadrillas.length; i++) {
				this.arrayMadrillas[i].isDisabled = false
				this.disabledCounter = 0
			}
			for (let i = 0; i < this.arrayMadrillasSelected.length; i++) {
				if (this.arrayMadrillasSelected[i].postura.madrilla.id !== "") {
					this.arrayMadrillas.find(
						(x) => x.id == this.arrayMadrillasSelected[i].postura.madrilla.id
					).isDisabled = true
					this.disabledCounter++
				}
			}
			if (this.disabledCounter == this.arrayPosturas.length) {
				// this.addNewPostura()
			}

			this.arrayMadrillas = this.arrayMadrillas.sort((a, b) => a.isDisabled - b.isDisabled)
		},
		scrollToBotom(index) {
			if (index + 1 > 2) {
				let myRef = this.$refs["modal-add-postura-eggs"].$refs.content.querySelector("div")
				myRef.style.scrollBehavior = "smooth"
				setTimeout(() => {
					myRef.scrollTop = myRef.scrollHeight - myRef.clientHeight
				}, 100)
			}
		},
		async getActiveMadrillasByActivePosturas() {
			try {
				// const { data } = await axios.get("api/postura/get-madrillas-being-used")
				const { data } = await axios.get(`/api/encaste/recommended/get-madrillas-recommended`, {
					params: {
						perpage: 200,
						page: 1,
					},
				})
				this.arrayMadrillas = data.madrillas
			} catch (error) {
				console.error(error)
				throw error
			}
		},
		async getActivePadrillos() {
			try {
				this.arrayPadrillos = [{ ...this.padrillo }]
			} catch (error) {
				console.error(error)
				throw error
			}
		},
		async save() {
			if (!(await this.$refs.form.validate())) {
				return
			}

			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.loading = true
			let payload = [...this.arrayPosturas]
			// if (this.is_eggs_found) {
			// hace lo mismo que por default, crea la postura en pendiente
			payload = this.arrayPosturas.map((postura) => ({ ...postura, active: 0 }))
			// }
			const { status, message, madrillasWithPostura, posturas } = await posturasService.savePostura(payload)

			if (status) {
				this.showSuccessToast(message)
				this.$emit("refresh")
				this.$emit("hidden")
			} else {
				this.showToast("danger", "top-right", "Pollos", "DangerIcon", message)
			}
			this.loading = false
		},
	},
}
</script>

<style lang="scss">
.header-date-total {
	background: #ffffff;
	padding-bottom: 5px;
	padding-top: 10px;
	position: sticky;
	top: -12px !important;
	z-index: 99999;
}

.dark-layout {
	.header-date-total {
		background-color: #283046;
	}
}
</style>
